import request from '@/utils/request'

export function getData(data) {
    return request({
        url: 'https://zjx.ink/api/getdata',
        // url: 'http://127.0.0.1:8000/api/getdata',
        method: 'post',
        data: data
    })
}

export function getdatabyid(data) {
    return request({
        url: 'https://zjx.ink/api/getdatabyid?id='+data,
        // url: 'http://127.0.0.1:8000/api/getdatabyid?id='+data,
        method: 'post'
    })
}

export function getip() {
    return request({
        url: 'https://www.zjx.ink/api/getip',
        // url: 'http://127.0.0.1:8000/api/getdatabyid?id='+data,
        method: 'get'
    })
}
