// 路由配置文件router/index.js
// 导入vue和vue-router
import Vue from 'vue'
import VueRouter from 'vue-router'
// 导入首页视图文件
import Index from '../views/Index.vue'
// 注册路由插件
// Vue.use()用来注册插件，会调用传入对象的install方法
Vue.use(VueRouter)

// 定义路由规则，即URL路径与视图的映射关系
const routes = [
    {
        path: '/',
        name: 'Index',
        // 展示首页
        component: Index
    },
    {
        path: '/details/:id',
        name: 'details',
        // 开启props，会把URL中的参数传递给组件，组件通过props来接收
        props: true,
        // 路由层面的代码分割，为这个路径生成一个独立的代码块
        // 当该路径被访问时实现懒加载功能
        component: () => import(/* webpackChunkName: "details" */'../views/details.vue')
    }

]

// 创建路由对象router，传入路由规则
const router = new VueRouter({
    // 默认模式是hash模式，不需要显示配置
    routes
})

// 导出路由对象
export default router
