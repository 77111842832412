<template>
  <el-container>
    <el-header  style="background-image: linear-gradient(-225deg, #3d3393 0%, #2b76b9  29%, #2cacd1, #35eb93  100%);">
      <heads></heads>
    </el-header>
    <el-main style="background-image: linear-gradient(to top, #f3e7e9 0%, #e3eeff 99%, #e3eeff 100%);">
      <maindata class="dat" ></maindata>
    </el-main>
    <el-footer height="60px" style="background-image: linear-gradient(-225deg, #CBBACC 0%, #2580B3 100%) ;padding: 10px;color: white">
      <ends></ends>
    </el-footer>
  </el-container>
</template>

<script>

import maindata from './maindata';
import heads from './heads';
import ends from './ends';

export default {
  name: 'App',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    maindata,
    // eslint-disable-next-line vue/no-unused-components
    heads,
    ends
  },
  created() {
    document.title = "悠然星";

  }
}
</script>

<style>
.el-header {
  height: 70px;
}
.el-main {
  padding: 0;
  height: calc(100vh - 140px);
}
.el-container {
  min-height: 100vh;
}
.dat{
  align-content: center;
  width: 70% ;
  margin: 6px 6px 6px 15%;


}

</style>
