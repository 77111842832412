<template>
  <!--    头部-->
  <el-row>
  <!--      logo-->
  <el-col :span="3">
    <div class="grid-content " @click="index()" >
      <img :src="src" style="width: auto; height: 46px ; margin-left: 60px">

    </div>
  </el-col>
  <!--其他-->
  <el-col :span="15">
    <div class="grid-content "></div>
  </el-col>
    <el-col :span="6">
      <div class="grid-content" style="color: white ; padding: 18px">{{nowTime}}</div>
    </el-col>
  <!--内容-->
  </el-row>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "heads",
  data() {
    return {
      src: require('../assets/alogo.png'),
      //时间1定义
      timer: undefined,
      nowTime: new Date(),
    }
  },
  created() {
//时间1方法

// 要显示时间，在渲染页面之前一直调用该函数，对this.time进行赋值开启定时

    this.timer = setInterval(() => {

      this.nowTime = new Date().toLocaleString();

    });

  },
  methods: {
    index() {
      this.$router.push({name:'Index'});
    },
    //时间2方法
    FormatTime() {
      //设置返回显示的日期时间格式
      var date = new Date();
      var year = this.formatTime(date.getFullYear());
      var month = this.formatTime(date.getMonth() + 1);
      var day = this.formatTime(date.getDate());
      var hour = this.formatTime(date.getHours());
      var minute = this.formatTime(date.getMinutes());
      var second = this.formatTime(date.getSeconds());
      var weekday = date.getDay();
      var weeks = new Array(
          "星期日",
          "星期一",
          "星期二",
          "星期三",
          "星期四",
          "星期五",
          "星期六"
      );
      var week = weeks[weekday];
      //这里设置你要显示的格式
      return `${year}年-${month}月-${day}日 ${hour}:${minute}:${second} ${week}`;
    },
    formatTime(n) {
      //判断时间是否需要加0
      if (n < 10) {
        return "0" + n;
      } else {
        return n;
      }
    }
  }


}
</script>

<style scoped>
.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  padding: 5px;
  border-radius: 4px;
  min-height: 36px;
  margin-right: 100px;
}
</style>
