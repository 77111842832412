<template>
  <el-row type="flex" justify="center">
    <el-col :span="18">
      <div class="grid-content bg-purple">
        <el-card class="box-card" style="width: 100% "
                 body-style="background-image: linear-gradient(to top, #ffffff 0%, #97d9e1 10%, #d9afd9 100%);">


          <div v-for="records in recordslist" :key="records" class="text item">
            <el-card class="box-card-data" shadow="hover"
                     body-style="background-image: linear-gradient(-225deg, #daf1f4 0%, #9dd4e0 100%);">

              <!--     兜底结束     -->
              <div style="width: 100%;height: 100%" v-if="!dataStatus">
                <span style="font-size: 16px ;
                width: 90%;
                height: 400px;
                margin: 10px;
                padding: 18px;
                margin-left: 3%;font-weight: bold;
                color: coral">在这个充满挑战的世界里，我们每个人都在以自己的方式寻找光明和希望。无论遇到多大的困难，都要记得：每一步前进都是向着更好的自己迈进。当天空乌云密布时，请不要忘记太阳依旧在云端之上照耀；当心中感到迷茫与困惑时，请记得内心深处总有一盏不灭的灯塔指引方向。每一天都是一次新的开始，每一次尝试都有可能带来意想不到的成功。即使是最微小的努力，也能够汇聚成推动世界向前的力量。所以，请保持乐观的心态，勇敢地面对生活中的每一个挑战。</span>
                <br> <br><br>
                <span style="font-size: 16px ;
                width: 90%;
                height: 400px;
                margin: 10px;
                padding: 22px;
                margin-left: 3%;font-weight: bold;
                color: darkslateblue">服务目前正在持续优化中，敬请期待全新面貌！</span>
              </div>
              <!--     兜底结束     -->

              <div style="width: 100%;height: 100%" v-if="dataStatus" @click="selectOne(records.id)">
                <b>
                  <span style="font-size: 18px ;color:#453a94  ;margin-left: 3%">{{ records.title }}</span>
                  <br>
                  <span style="font-size: 12px ;color: #cc208e  ; margin-left: 3%">{{
                      records.intster
                    }}   {{ records.name }}</span>
                  <br/>
                </b>
                <span
                    style="font-size: 12px ;display: block; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;width: 90%;margin: 5px;padding: 5px;margin-left: 3%;color: dimgray">{{
                    records.content
                  }}</span>
              </div>
            </el-card>
          </div>
          <!--    底栏-->
          <el-row type="flex" justify="center" v-if="dataStatus">
            <el-pagination
                background
                layout="prev, pager, next"
                :total="total"
                :page-size="queryParams.size"

                @prev-click="prevClick"
                @next-click="nextClick"
                @current-change="currentChange"
            >
            </el-pagination>
          </el-row>


        </el-card>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import {getData} from "@/api/maindata";

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      total: 0,
      recordslist: [
        {
          name: "",
          intster: "",
          title: "",
          content: ""
        }
      ],
// 查询参数
      queryParams: {
        pages: 1,
        size: 5
      },
      availHeight: 800,
      car: 120,
      dataStatus: false
    }
  },
  created() {
    this.getdata();
  },
  methods: {
    getdata() {
      this.availHeight = document.body.offsetHeight;
      this.queryParams.size = (this.availHeight - 250) / this.car;
      this.queryParams.size = this.queryParams.size >= 1 ? this.queryParams.size : 1;
      this.queryParams.size = this.queryParams.size <= 30 ? this.queryParams.size : 30;

      getData(this.queryParams).then(res => {
        if (res.data.list) {
          this.dataStatus = true
          this.recordslist = res.data.list;
          this.queryParams.pageNum = res.data.pages;
          this.total = res.data.total;
        }
      })
    },
    prevClick() {
      this.queryParams.pages -= 1;
      this.getdata();
    },
    nextClick() {
      this.queryParams.pages += 1;
      this.getdata();
    },
    currentChange(val) {
      this.queryParams.pages = val
      this.getdata();
    },
    selectOne(val) {
      this.$router.push({name: 'details', params: {id: val}});
    }
  }


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.bg-purple {
  background: #d3dce6;
}


.grid-content {

  border-radius: 4px;
  min-height: 36px;
}

.box-card-data {
  margin: 15px
}

.dat {
  align-content: center;
  width: 70%;
  margin: 6px 6px 6px 15%;


}
</style>
