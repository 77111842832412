<template>
  <!--    底栏-->
  <el-row type="flex" justify="center">
    <el-col :span="10">
      <div class="grid-content" style="align-content: center;text-align: center">

        <span>©2022-2024 悠然星 |</span>
        <span>
          <a href="https://beian.miit.gov.cn/" target="_blank" style="color: white ;text-decoration: none">陕ICP备2022007552号-1</a>
        <br/>
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=61072302000147"
             style="color: white ;display:inline-block;text-decoration:none;height:20px;line-height:20px;">
                    <img src="../state/备案图标.png"
                         style="float:left;" width="20px" height="20px" />  陕公网安备 61072302000147号
                  </a>

        </span>
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "ends"
}
</script>

<style scoped>
.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}


</style>
